.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: grey;
  font-size: 1.2rem;
  font-weight: 600;
}

.header select {
  font-size: .9rem;
}

.body {
  padding: .5rem;
}

.sumary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .25rem .5rem;
}

.searchBox {
  padding: .25rem .5rem;
}

.searchBox input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid grey;
}