.boxes-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.boxes-body {
  display: flex;
  flex-wrap: wrap;
  padding: .5rem;
}

.box {
  display: flex;
  height: min-content;
  border: 2px solid grey;
  border-radius: .4rem;
  margin: .5rem;
  padding: .25rem;
  gap: 1rem;
  box-shadow: 0 8px 6px -6px black;
}

.box-sumary {
  border: 2px solid darkcyan;
  box-shadow: 0 8px 6px -6px darkcyan;
}

.box:hover {
  font-size: 1.1rem;
  transition: 0.2s;
}

.box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  font-size: 2.3rem;
}

.box-body {
  display: flex;
  flex-direction: column;
}

.box-header {
  display: flex;
  justify-content: flex-start;
  color: mediumblue;
  font-weight: 600;
}

.box-header-sumary {
  color: darkgreen;
  font-weight: 700;
}

.box-name {
  display: flex;
  font-size: 1.2rem;
  font-weight: 550;
}